<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5"><span class="lfont">ທັກສະ ແລະ ຄວາມສາມາດ</span></span>
                </v-card-title>
                <v-card-text>
                    <v-container class="lfont">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <label>ຫົວຂໍ້</label>
                                    <v-text-field required dense :rules="dataRules" v-model="items.title"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <label>ຄວາມສາມາດ</label>
                                    <v-radio-group v-model="items.ability_level" row :rules="dataRules" required>
                                        <v-radio :label="item.lable" :value="item.value"
                                            v-for="(item, i) in ability_level"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add">
                        add
                    </v-btn>
                    <v-btn color="red darken-1" text @click="$emit('action', false)">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    props: {
        dialog: ""
    },
    data: () => ({
        valid: true,
        items: {
            title: "",
            ability_level: "",
        },
        dataRules: [
            v => !!v || 'required !',
        ],
        ability_level: [
            {
                "lable": "ດີຫຼາຍ",
                "value": 4
            },
            {
                "lable": "ດີ",
                "value": 3
            },
            {
                "lable": "ປານກາງ",
                "value": 2
            },
            {
                "lable": "ພໍໃຊ້",
                "value": 1
            },
        ]
    }),
    methods: {
        add() {
            if (!this.$refs.form.validate()) return;
            this.$emit('add', this.items)
            this.$emit('action', false)
        }
    },
}
</script>

<style lang="scss" scoped></style>