<template>
    <div>
        <table class="table">
            <tr>
                <th>ຫົວຂໍ້</th>
                <th>ຄວາມສາມາດ</th>
                <th width="80"></th>
            </tr>
            <tbody>
                <tr v-for="(item, i) in data">
                    <td>{{ item.title }}</td>
                    <td>
                        <v-radio-group v-model="item.ability_level" row class="m-0">
                            <v-radio :label="level_item.lable" :value="level_item.value"
                                v-for="level_item in ability_level"></v-radio>
                        </v-radio-group>

                    </td>
                    <td>
                        <v-btn icon color="pink" @click="$emit('delete', i)">
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        data: ''
    },
    data() {
        return {
            ability_level: [
                {
                    "lable": "ດີຫຼາຍ",
                    "value": 4
                },
                {
                    "lable": "ດີ",
                    "value": 3
                },
                {
                    "lable": "ປານກາງ",
                    "value": 2
                },
                {
                    "lable": "ພໍໃຊ້",
                    "value": 1
                },
            ]
        }
    },

}
</script>

<style lang="scss" scoped>
.table {
    margin-top: 10px;

    tr {
        th {
            border: 1px solid black !important;
        }

        td {
            border: 1px solid black !important;
        }
    }
}
</style>