<template>
    <div>
        <table class="table">
            <tr>
                <th>ລະດັບການສຶກສາ</th>
                <th>ສະຖາບັນການສຶກສາ</th>
                <th>ສາຂາ/ວິຊາຮຽນ</th>
                <th>ຕັ້ງແຕ່ປີໃດ</th>
                <th>ຫາປີໃດ</th>
                <th width="50"></th>
            </tr>
            <tbody>
                <tr v-for="(edu_item, i) in data">
                    <td>{{ edu_item.level }}</td>
                    <td>{{ edu_item.institutions }}</td>
                    <td>{{ edu_item.branch }}</td>
                    <td>{{ edu_item.from_year }}</td>
                    <td>{{ edu_item.to_year }}</td>
                    <td>
                        <v-btn icon color="pink" @click="$emit('delete', i)">
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        data: {}
    }
}
</script>

<style lang="scss" scoped>
.table {
    margin-top: 10px;

    tr {
        th {
            border: 1px solid black !important;
        }

        td {
            border: 1px solid black !important;
        }
    }
}
</style>