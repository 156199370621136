<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5"><span class="lfont">ກາສຶກສາ</span></span>
                </v-card-title>
                <v-card-text>
                    <v-container class="lfont">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <label>ລະດັບການສຶກສາ</label>
                                    <v-select :items="edu_level" :rules="dataRules" required dense
                                        v-model="item.level"></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <label>ສະຖາບັນການສຶກສາ</label>
                                    <v-text-field required dense :rules="dataRules"
                                        v-model="item.institutions"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <label>ສາຂາ/ວິຊາຮຽນ</label>
                                    <v-text-field dense required :rules="dataRules" v-model="item.branch"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <label>ຕັ້ງແຕ່ປີໃດ</label>
                                    <v-text-field required dense :rules="yearRules" v-model="item.from_year"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <label>ຫາປີໃດ</label>
                                    <v-text-field required dense :rules="yearRules" v-model="item.to_year"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>

                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_edu">
                        add
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="$emit('action', false)">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    props: {
        dialog: ""
    },
    data: () => ({
        valid: true,
        dataRules: [
            v => !!v || 'required !',
        ],
        yearRules: [
            v => !!v || 'required !',
            v => (!isNaN(v) && v.length <= 4) || '4 Number !',
            v => (!isNaN(v) && v.length >= 4) || '4 Number !',
        ],
        item: {
            level: "",
            institutions: "",
            branch: "",
            from_year: "",
            to_year: "",
        },
        edu_level: ['Intermediate', 'Advanced', 'Bachelor', 'Master', 'PhD']
    }),
    methods: {
        add_edu() {
            if (!this.$refs.form.validate()) return;
            this.$emit('add', this.item)
            this.$emit('action', false)
        }
    },
}
</script>

<style lang="scss" scoped></style>