<template>
    <div class="main-content">
        <div class="container lfont">
            <v-form ref="form" v-model="valid" lazy-validation>
                <div class="content-section">
                    <h3 class="text-h5 text-center"><span class="lfont">ແບບຟອມສະໝັກງານ</span></h3><br><br>
                    <v-row>
                        <v-col cols="12" md="3">
                            <div class="choose-image">
                                <div class="show-image">
                                    <i class="fas fa-file-pdf"></i>
                                    <input @change="PreviewPDF" type="file" class="input-file-image" />
                                    <p>Resume File(PDF)</p>
                                    <embed class="embed-pdf" v-if="showPDF" :src="showPDF" type="application/pdf"
                                        accept="application/pdf" />
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="3">
                            <label class="text-primary">ຕຳແໜ່ງ</label>
                            <p>{{ position }}</p>
                            <!-- <v-text-field v-model="position" :rules="dataRules" label="ຕຳແໜ່ງ" required
                                disabled></v-text-field> -->
                        </v-col>

                        <v-col cols="12" md="4">
                            <label class="mr-4 text-primary">ເງີນເດືອນທີ່ຕ້ອງການ</label>
                            <div style="display: flex;">
                                <vuetify-money dense :options="$store.state.formatSalaryNumber" v-model="items.salary"
                                    persistent-hint type="number" required :rules="dataRules" />
                                <v-select dense :items="salary_unit_items" v-model="items.salary_unit" item-text="short"
                                    item-value="short" class="period_unit" width="50"></v-select>
                            </div>

                        </v-col>

                    </v-row>
                </div>
                <div class="content-section">
                    <h3 class="text-h6 "><span class="lfont ">ຂໍ້ມູນສ່ວນຕົວ</span></h3><br>
                    <v-row>
                        <v-col cols="12" md="4">
                            <label class="mr-4 text-primary">ຊື່</label>
                            <v-text-field v-model="items.name" :rules="dataRules" required></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                            <label class="mr-4 text-primary">ນາມສະກຸນ</label>
                            <v-text-field v-model="items.surname" :rules="dataRules" label="" required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                            <label class="mr-4 text-primary">ເພດ</label>
                            <v-radio-group row :rules="dataRules" v-model="items.gender">
                                <v-radio label="Male" value="male"></v-radio>
                                <v-radio label="Female" value="female"></v-radio>
                            </v-radio-group>
                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="12" md="4">
                            <label class="mr-4 text-primary">ວັນເດືອນປີເກິດ</label>
                            <DatePicker class="date-time" style="width: 100%" type="date" valueType="format"
                                slot="activator" :append-to-body="true" v-model="items.birthday" :rules="dataRules"
                                requried></DatePicker>
                        </v-col>

                        <v-col cols="12" md="4">
                            <label class="mr-4 text-primary">ສະຖານະ</label>
                            <div class="gender">
                                <v-radio-group row :rules="dataRules" v-model="items.married_status">
                                    <v-radio label="Single" value="single"></v-radio>
                                    <v-radio label="Married" value="married"></v-radio>
                                </v-radio-group>
                            </div>

                        </v-col>

                    </v-row><br>

                    <h3 class="text-h6 "><span class="lfont">ທີ່ຢູ່</span></h3><br>
                    <v-row>
                        <v-col cols="12" md="4">
                            <label class="mr-4 text-primary">ແຂວງ</label>
                            <v-autocomplete filled dense item-text="name" item-value="id" :items="provinces" hide-selected
                                :rules="dataRules" persistent-hint required v-model="province_id" label="">
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="4">
                            <label class="mr-4 text-primary">ເມືອງ</label>
                            <v-autocomplete filled dense item-text="name" item-value="id" :items="districts" hide-selected
                                :rules="dataRules" persistent-hint required v-model="items.district_id" label="">
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="4">
                            <label class="mr-4 text-primary">ບ້ານ</label>
                            <v-text-field v-model="items.village" :rules="dataRules" label="" required></v-text-field>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <label class="mr-4 text-primary">ສັນຊາດ</label>
                            <v-select v-model="items.nationality" :items="nationality"
                                :item-text="item => $t('Employee.form.Nationalities.' + item.name)" item-value="name"
                                :rules="dataRules" required></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                            <label class="mr-4 text-primary">ເບີໂທ</label>
                            <v-text-field v-model="items.tel" :rules="dataRules" label="" required></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                            <label class="mr-4 text-primary">ອີເມວ</label>
                            <v-text-field v-model="items.email" :rules="dataRules" label="" required></v-text-field>
                        </v-col>

                    </v-row>
                </div>


                <div class="content-section">
                    <h3 class="text-h6 "><span class="lfont">ການສຶກສາ</span>
                        <v-btn class="mx-2" fab dark x-small color="primary" @click="modalAddEduDialog = true">
                            <v-icon dark>
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </h3>
                    <div v-if="items.edu.length > 0">
                        <showEdu :data="items.edu" @delete="(val) => removeEduItem(val)" />
                    </div>


                </div>

                <div class="content-section">
                    <h3 class="text-h6 "><span class="lfont">ປະສົບການເຮັດວຽກ</span>
                        <v-btn class="mx-2" fab dark x-small color="primary" @click="modalAddExpDialog = true">
                            <v-icon dark>
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </h3>
                    <div v-if="items.experience.length > 0">
                        <showExperience :data="items.experience" @delete="(val) => removeExpItem(val)" />
                    </div>


                </div>

                <div class="content-section">
                    <h3 class="text-h6 "><span class="lfont">ທັກສະ ແລະ ຄວາມສາມາດ</span>
                        <v-btn class="mx-2" fab dark x-small color="primary" @click="modalAddSkillDialog = true">
                            <v-icon dark>
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </h3>
                    <div v-if="items.skills.length > 0">
                        <showSkill :data="items.skills" @delete="(val) => removeSkillItem(val)" />
                    </div>


                </div>
                <div class="content-section">
                    <v-btn class="text-white" color="primary lfont" @click="submit_apply_job">
                        ສົ່ງຟອມສະໝັກງານ
                    </v-btn>
                </div>

            </v-form>
        </div>
        <modalAddEdu :dialog="modalAddEduDialog" @action="(val) => modalAddEduDialog = val" @add="(val) => add_edu(val)" />
        <modalAddExperienceVue :dialog="modalAddExpDialog" :salary_unit_items="salary_unit_items"
            @action="(val) => modalAddExpDialog = val" @add="(val) => add_exp(val)" />
        <modalAddSkill :dialog="modalAddSkillDialog" @action="(val) => modalAddSkillDialog = val"
            @add="(val) => add_skill(val)" />
        <Loading v-if="isLoading" />

    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "@/components/Loading";
import showEdu from "@/components/Recruitments/apply/showEdu.vue";
import showExperience from "@/components/Recruitments/apply/showExperience.vue";
import showSkill from "@/components/Recruitments/apply/showSkill.vue";
import modalAddEdu from "@/components/Recruitments/apply/modalAddEdu.vue";
import modalAddExperienceVue from "@/components/Recruitments/apply/modalAddExperience.vue";
import modalAddSkill from "@/components/Recruitments/apply/modalAddSkill.vue";
export default {
    components: {
        DatePicker,
        Loading,
        showEdu,
        showExperience,
        showSkill,
        modalAddEdu,
        modalAddExperienceVue,
        modalAddSkill,
    },
    data() {
        return {
            modalAddExpDialog: false,
            modalAddEduDialog: false,
            modalAddSkillDialog: false,
            isLoading: true,
            showPDF: "",
            valid: true,
            position: "",
            salary: "",
            recruitment_data: [],
            request_id: this.$route.params.id,
            dataRules: [
                v => !!v || '*required'
            ],
            nationality: [],
            items: {
                position_id: "",
                profile: "",
                salary: 0,
                salary_unit: "",
                name: "",
                surname: "",
                gender: "",
                birthday: "",
                married_status: "",
                nationality: "",
                village: "",
                district_id: "",
                tel: "",
                email: "",
                edu: [],
                experience: [],
                skills: []

            },
            salary_unit_items: [],
            province_id: "",
            provinces: [],
            districts: [],
            selectedProvince: [],
            server_errors: {
                position_id: "",
                profile: "",
                salary: "",
                salary_unit: "",
                name: "",
                surname: "",
                gender: "",
                birthday: "",
                married_status: "",
                nationality: "",
                village: "",
                district_id: "",
                tel: "",
                email: "",
                edu: [],
                experience: [],
                skills: []

            }
        }
    },
    methods: {
        submit_apply_job() {
            if (!this.$refs.form.validate()) return;

            this.isLoading = true;
            this.$axios
                .post(`applicants/apply/job/${this.request_id}`, this.items)
                .then((res) => {
                    this.isLoading = false;
                    if (res.status === 200) {
                        this.$router
                            .push({
                                name: "apply.job.success",
                                query: {
                                    id: this.request_id,
                                    name: this.items.name,
                                    tel: this.items.tel,
                                    position: this.position
                                }
                            })
                            .catch(() => { });
                        setTimeout(() => {
                            this.$notification.OpenNotification(
                                "ການສະໝັກງານ",
                                "ທ່ານສະໝັກສຳເລັດ",
                                "top-right",
                                "success",
                                3000,
                            );
                        }, 300);
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        let obj = error.response.data.errors;
                        if (!obj) {
                            obj = error.response.data;
                            let res = "";
                            for (let [key, value] of Object.entries(obj)) {
                                res = value;
                            }
                            this.$notification.ShowErrors("top-right", "danger", 3000, res);
                        } else {
                            for (let [key, value] of Object.entries(obj)) {
                                this.server_errors[key] = value[0];
                                this.$notification.ShowErrors(
                                    "top-right",
                                    "danger",
                                    3000,
                                    (this.server_errors[key] = value[0])
                                );
                            }
                        }
                    }
                });
        },
        add_skill(item) {
            this.items.skills.push({
                title: item.title,
                ability_level: item.ability_level,
            });
        },
        add_exp(item) {
            this.items.experience.push({
                workplace: item.workplace,
                position: item.position,
                period_value: item.period_value,
                period_unit: item.period_unit,
                salary: item.salary,
                salary_unit: item.salary_unit,
                reason_resiged: item.reason_resiged,
            });
        },
        add_edu(item) {
            this.items.edu.push({
                level: item.level,
                institutions: item.institutions,
                branch: item.branch,
                from_year: item.from_year,
                to_year: item.to_year,
            });
        },
        removeEduItem(index) {
            this.items.edu.splice(index, 1);
        },
        removeExpItem(index) {
            this.items.experience.splice(index, 1);
        },
        removeSkillItem(index) {
            this.items.skills.splice(index, 1);
        },
        PreviewPDF: function (event) {
            const input = event.target;
            const file = input.files[0];
            const fileType = file["type"];
            const validImageTypes = ["application/pdf"];
            if (!validImageTypes.includes(fileType)) {
                this.$notification.ShowErrors(
                    "top-right",
                    "danger",
                    3000,
                    "image must be PDF"
                );
            } else if (input.files && input.files[0]) {
                this.imagePDF = input.files[0];
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.showPDF = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
                this.UploadPDF(this.imagePDF);
            }
        },

        UploadPDF(imagePDF) {
            let formData = new FormData();
            formData.append("imageFile", imagePDF);
            this.$axios.post(`upload-file`, formData).then((res) => {
                if (res.status === 200) {
                    this.items.profile = res.data.filename;
                }
            });
        },
        fetchJob() {
            this.$axios.get(`/applicants/apply/job/${this.request_id}`).then((res) => {
                if (res.status === 200) {
                    this.recruitment_data = res.data.data;
                    this.items.position_id = this.recruitment_data.position_id;
                    this.position = this.recruitment_data.position;
                }
                this.isLoading = false;
            }).catch((error) => {
                this.isLoading = false;
                if (error.response.status === 422) {
                    let obj = error.response.data.errors;
                    if (!obj) {
                        obj = error.response.data;
                        let res = "";
                        for (let [key, value] of Object.entries(obj)) {
                            res = value;
                        }
                        this.$notification.ShowErrors("top-right", "danger", 3000, res);
                    } else {
                        for (let [key, value] of Object.entries(obj)) {
                            this.server_errors[key] = value[0];
                            this.$notification.ShowErrors(
                                "top-right",
                                "danger",
                                3000,
                                (this.server_errors[key] = value[0])
                            );
                        }
                    }
                }
            });
        },
        fetchNationality() {
            this.$axios
                .get(`nationality`)
                .then((res) => {
                    if (res) {
                        this.nationality = res.data.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        FetchDistrict() {
            this.$axios
                .get("list/addresses")
                .then((res) => {
                    const items = res.data;
                    this.provinces = items.provinces;
                    this.selectedProvince = { ...this.provinces[0] };
                    this.filterDistricts = items.districts;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        FilterDistricts(provinceID) {
            this.districts = this.filterDistricts.filter((item) => {
                if (item.province_id == provinceID) {
                    return item;
                }
            });
        },
        fetch_currency() {
            this.$axios
                .get(`currency`)
                .then((res) => {
                    this.salary_unit_items = res.data.data;
                })
                .catch(() => { });
        }
    },
    watch: {
        province_id: function (provinceID) {
            this.FilterDistricts(provinceID);
        },
    },
    created() {
        this.FetchDistrict()
        this.fetchJob()
        this.fetchNationality()
        this.fetch_currency()
    },
}
</script>

<style lang="scss" scoped>
.main-content {
    background-color: #F0F0F0;
    min-height: 100vh;

    .section-action {
        padding: 30px;
    }

    .content-section {
        background-color: white;
        padding: 20px;
        margin: 5px 0px;

        .gender {
            display: flex;
        }

        .table {
            margin-top: 10px;

            tr {
                th {
                    border: 1px solid black !important;
                }

                td {
                    border: 1px solid;
                }
            }
        }
    }

    .period_unit {
        width: 50px !important;
        margin-left: 10px;
    }
}
</style>