<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5"><span class="lfont">ປະສົບການເຮັດວຽກ</span></span>
                </v-card-title>
                <v-card-text>
                    <v-container class="lfont">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <label>ບ່ອນປະຈຳການ</label>
                                    <v-text-field required dense :rules="dataRules"
                                        v-model="items.workplace"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <label>ຕຳແໜ່ງ</label>
                                    <v-text-field dense required :rules="dataRules" v-model="items.position"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <label>ໄລຍະເວລາ</label>
                                    <div class="salary-section">
                                        <v-text-field dense required :rules="salaryRules"
                                            v-model="items.period_value"></v-text-field>
                                        <v-select dense :items="period_unit_items" item-text="show" item-value="value"
                                            v-model="items.period_unit" class="period_unit"></v-select>
                                    </div>

                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <label>ເງີນເດືອນ</label>
                                    <div class="salary-section">
                                        <vuetify-money dense :options="$store.state.formatSalaryNumber"
                                            v-model="items.salary" persistent-hint type="number" required
                                            :rules="salaryRules" />
                                        <v-select dense :items="salary_unit_items" v-model="items.salary_unit"
                                            item-text="short" item-value="short" class="period_unit"></v-select>
                                    </div>

                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <label>ເຫດຜົນທີ່ລາອອກ</label>
                                    <v-textarea dense required :rules="dataRules" v-model="items.reason_resiged"
                                        rows="3"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add">
                        add
                    </v-btn>
                    <v-btn color="red darken-1" text @click="$emit('action', false)">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    props: {
        dialog: "",
        salary_unit_items: []
    },
    data: () => ({
        valid: true,
        dataRules: [
            v => !!v || 'required !',
        ],
        salaryRules: [
            v => !!v || 'required !',
            //v => (!isNaN(v) && v.length <= 10) || 'Number !',
        ],
        items: {
            workplace: "",
            position: "",
            period_value: "",
            period_unit: "year",
            salary: "",
            salary_unit: "LAK",
            reason_resiged: "",
        },
        period_unit_items: [
            {
                "show": "Year",
                "value": "year",
            },
            {
                "show": "Month",
                "value": "month",
            }
        ],
    }),
    methods: {
        add() {
            if (!this.$refs.form.validate()) return;
            this.$emit('add', this.items)
            this.$emit('action', false)
        },
    },
    created() {

    },
}
</script>

<style lang="scss" scoped>
.salary-section {
    display: flex;

    .period_unit {
        width: 100px;
    }
}
</style>