<template>
    <div>
        <table class="table">
            <tr>
                <th>ບ່ອນປະຈຳການ</th>
                <th>ຕຳແໜ່ງ</th>
                <th>ໄລຍະເວລາ</th>
                <th>ເງີນເດືອນ</th>
                <th>ເຫດຜົນທີ່ລາອອກ</th>
                <th width="50"></th>
            </tr>
            <tbody>
                <tr v-for="(item, i) in data ">
                    <td>{{ item.workplace }}</td>
                    <td>{{ item.position }}</td>
                    <td>{{ item.period_value }} {{ item.period_unit }}</td>
                    <td>{{ $helpers.numberFormatter(item.salary) }} {{ item.salary_unit }}</td>
                    <td>{{ item.reason_resiged }}</td>
                    <td>
                        <v-btn icon color="pink" @click="$emit('delete', i)">
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        data: {}
    }

}
</script>

<style lang="scss" scoped>
.table {
    margin-top: 10px;

    tr {
        th {
            border: 1px solid black !important;
        }

        td {
            border: 1px solid black !important;
        }
    }
}
</style>